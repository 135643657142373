@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    @font-face {
        font-family: "Expressa";
        src: url(/public/Expressa-Serial-Regular.ttf);
    }

}

@layer components {
    .newfont {
        @apply text-[32px] font-black;
        font-family: Expressa;
    }
}

@layer utilities {


    .rotate {
        writing-mode: vertical-lr;

        text-orientation: mixed;
    }

    :focus-visible {
        @apply outline-none;
    }

}